import Order from "./Order";
import { useState, useEffect } from "react";
import axios from "axios";
// import useSound from "use-sound";
// import noticeSound from "../../sound/juntos-607.mp3";
import { useAuth0 } from "@auth0/auth0-react";

import { Redirect } from "react-router-dom";
import Feature from "../../util/feature";

import "./OrderList.css";

const OrderList = ({ businessId }) => {
  const [openOrders, setOpenOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [orders, setOrders] = useState(null);
  const [ordersLength, setOrdersLength] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  // const [play] = useSound(noticeSound);

  var io = require("socket.io-client");

  const endpoint = process.env.REACT_APP_API_ORIGIN;
  var socket = io.connect(endpoint);

  socket.on("connect", function (socket) {
    console.log("Connected!");
  });

  useEffect(() => {
    getOrdersFromServer();
  }, []);

  useEffect(() => {
    socket.on("newOrdersToService", getOrdersFromServer);
  }, []);

  useEffect(() => {
    orders &&
      setOpenOrders(
        orders.filter((order) => !order.delivered && order.status === "open")
      );
    orders &&
      setReadyOrders(
        orders.filter((order) => !order.delivered && order.status === "ready")
      );
  }, [orders]);

  useEffect(() => {
    if (orders && ordersLength && orders.length > ordersLength) {
      // play();
    }
    orders && setOrdersLength(orders.length);
  }, [orders]);

  async function getOrdersFromServer() {
    try {
      const token = await getAccessTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const newOrders = await axios.get(
        endpoint + "/orders/orders-list/" + businessId,
        config
      );

      let newOrderData = newOrders.data.data;
      setOrders(newOrderData);
    } catch (err) {
      console.error(err);
    }
  }

  function changeOrderStateInList(orderToBeChangedToReady) {
    orderToBeChangedToReady.ready = true;
    setOpenOrders(openOrders.filter((openOrder) => !openOrder.ready));
    setReadyOrders([...readyOrders, orderToBeChangedToReady]);
  }

  return (
    <div>
      <div className="orders-list">
        {readyOrders.length > 0 && <h2>ABHOLBEREIT ({readyOrders.length})</h2>}
        {readyOrders.map((order) => (
          <Order key={order._id} order={order} businessId={businessId} />
        ))}

        {openOrders.length > 0 && (
          <h2>OFFENE BESTELLUNGEN ({openOrders.length})</h2>
        )}

        {openOrders.length === 0 && readyOrders.length === 0 && (
          <h3>- Keine Bestellungen -</h3>
        )}

        {openOrders.map((order) => (
          <Order
            key={order._id}
            order={order}
            changeOrderStateInList={changeOrderStateInList}
            businessId={businessId}
          />
        ))}
      </div>
    </div>
  );
};

export default OrderList;
