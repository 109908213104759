const BusinessTab = ({
  isSelected,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  if (!isSelected) return null;
  // console.log("values: ", values);
  return (
    <div className="left-right-block">
      <div className="left-block">
        <label htmlFor="businessName-input">Geschäftsname*</label>
        <input
          type="text"
          id="businessName-input"
          name="business.businessName"
          className={
            errors?.business?.businessName
              ? "common-input error-border"
              : "common-input"
          }
          placeholder="z. B. super Laden"
          value={values?.business?.businessName}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
          required
        />
        {errors?.business?.businessName ? (
          <div className="error-block">
            <span className="error-message">
              {errors.business.businessName}
            </span>
          </div>
        ) : null}

        <label htmlFor="email-input">Email</label>
        <input
          name="business.email"
          type="text"
          id="email-input"
          className="common-input"
          placeholder="z. B. contact@gmail.de"
          value={values.business.email}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <label htmlFor="telefon-input">Telefon</label>
        <input
          name="business.phone"
          type="text"
          id="telefon-input"
          className="common-input"
          placeholder="z. B. 0049156585568"
          value={values.business.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <div className="right-block">
        <label htmlFor="strasse-input">Adresse</label>
        <input
          name="business.address"
          type="text"
          id="strasse-input"
          className="common-input"
          placeholder="z. B. Haupstrasse, 12, 10987 Berlin"
          value={values.business.address}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <label htmlFor="land-input">Land</label>
        <input
          name="business.country"
          type="text"
          id="land-input"
          className="common-input"
          placeholder="z. B. Deutschland"
          value={values.business.country}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default BusinessTab;
