import styled from "styled-components";
import React, { useRef, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import MenuCategory from "./Category";
import AddItemForm from "./Add.js";

import "./Menu.css";

const CategoryContainer = styled.div`
  border: ${(props) => (props.isDragging ? "2px solid lightgreen" : "")};
`;

const endpoint = process.env.REACT_APP_API_ORIGIN;

const MenuPage = ({ businessId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [menuData, setMenuData] = useState();
  const [showEditForm, setShowEditForm] = useState(false);
  const [editItem, setEditItem] = useState({});
  const categoryInput = useRef();

  useEffect(() => {
    getMenuData();
  }, []);

  const getMenuData = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        let res = await axios.get(`${endpoint}/menu/${businessId}`, config);
        if (!res?.data?.data) {
          await axios.put(`${endpoint}/menu/list/${businessId}`, {}, config);
          res = await axios.get(`${endpoint}/menu/${businessId}`, config);
        }

        setMenuData(res?.data?.data?.items);
      } catch (err) {
        // console.error(err);
      }
    })();
  };

  const handleVisibilityChange = (id) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        await axios.put(
          `${endpoint}/menu/available/${businessId}/${id}`,
          config
        );
      } catch (err) {
        console.error(err);
      }
    })();
  };

  const handleItemsPosition = (data, categoryId) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        await axios.put(
          `${endpoint}/menu/items-position/${businessId}/${categoryId}`,
          { data },
          config
        );
      } catch (err) {
        console.error(err);
      }
    })();
  };

  const handleEdit = (menuItem) => {
    setEditItem(menuItem);
    setShowEditForm(true);
  };

  const itemAdd = (category) => {
    setEditItem({ category });
    setShowEditForm(true);
  };

  const handleSubmit = (menuItem, isEdit) => {
    !isEdit
      ? (async () => {
          try {
            const token = await getAccessTokenSilently();
            const config = {
              headers: { Authorization: `Bearer ${token}` },
            };
            const res = await axios
              .post(
                `${endpoint}/menu/item/${businessId}`,
                {
                  data: {
                    ...menuItem,
                    extras: [],
                  },
                },
                config
              )
              .then(
                (res) => {
                  getMenuData();
                  setShowEditForm(false);
                },
                (error) => {
                  console.log(error);
                }
              );
          } catch (err) {
            console.log(err);
          }
        })()
      : (async () => {
          try {
            const token = await getAccessTokenSilently();
            const config = {
              headers: { Authorization: `Bearer ${token}` },
            };
            const res = await axios
              .put(
                `${endpoint}/menu/item/${businessId}`,
                {
                  data: {
                    ...menuItem,
                    extras: [],
                  },
                },
                config
              )
              .then(
                (res) => {
                  getMenuData();
                  setShowEditForm(false);
                },
                (error) => {}
              );
          } catch (err) {}
        })();
  };

  const addCategory = (name) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios
          .post(
            `${endpoint}/menu/category/${businessId}`,
            {
              data: {
                name,
                position: menuData.length,
              },
            },
            config
          )
          .then(
            (res) => {
              getMenuData();
            },
            (error) => {}
          );
      } catch (err) {}
    })();
  };

  const handleCategoryDelete = (categoryId) => {
    if (!window.confirm("Sind Sie sicher?")) return;
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios
          .delete(
            `${endpoint}/menu/category/${businessId}/${categoryId}`,
            config
          )
          .then(
            (res) => {
              getMenuData();
            },
            (error) => {
              console.log(error);
            }
          );
      } catch (err) {
        console.error(err);
      }
    })();
  };

  const handleEditCategory = (category) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios
          .put(
            `${endpoint}/menu/category/${businessId}`,
            {
              data: {
                ...category,
              },
            },
            config
          )
          .then(
            (res) => {
              getMenuData();
            },
            (error) => {}
          );
      } catch (err) {}
    })();
  };

  const handleClose = () => {
    setShowEditForm(false);
  };

  if (!menuData) {
    return null;
  }

  const handleCategoriesPosition = (data) => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        await axios.put(
          `${endpoint}/menu/categories-position/${businessId}`,
          { data },
          config
        );
      } catch (err) {
        console.error(err);
      }
    })();
  };

  const reorderCategory = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const putData = result.map(({ category }, index) => ({
      ...category,
      position: index,
    }));

    handleCategoriesPosition(putData);

    return result;
  };

  const reorderItemsWithMove = (
    list,
    sourceCategoryId,
    sourceIndex,
    destCategoryId,
    destIndex
  ) => {
    const result = Array.from(list);
    const sourceCatIndex = result.findIndex(
      ({ category }) => category._id === sourceCategoryId
    );
    const destCatIndex = result.findIndex(
      ({ category }) => category._id === destCategoryId
    );

    const sourceItems = result[sourceCatIndex].items;
    const destItems = result[destCatIndex].items;

    const [sourceRemoved] = sourceItems.splice(sourceIndex, 1); // rm from source
    destItems.splice(destIndex, 0, sourceRemoved); // add to dest
    result[sourceCatIndex].items = sourceItems;
    result[destCatIndex].items = destItems;

    // set new cat for moved item
    const menuItem = {
      ...sourceRemoved,
      category: result[destCatIndex].category._id,
    };

    // update moved item
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        axios
          .post(
            `${endpoint}/menu/item/${businessId}`,
            {
              data: {
                ...menuItem,
                extras: [],
              },
            },
            config
          )
          .then(
            () => {
              // reorder dest
              handleItemsPosition(
                destItems.map((item, index) => ({
                  id: item._id,
                  position: index,
                })),
                destCategoryId
              );
              // reorder source
              handleItemsPosition(
                sourceItems.map((item, index) => ({
                  id: item._id,
                  position: index,
                })),
                sourceCategoryId
              );
            },
            (error) => {
              console.log(error);
            }
          );
      } catch (err) {
        console.log(err);
      }
    })();

    return result;
  };

  const reorderItems = (list, categoryId, startIndex, endIndex) => {
    const result = Array.from(list);
    const index = result.findIndex(
      ({ category }) => category._id === categoryId
    );
    const items = result[index].items;
    const [removed] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, removed);
    result[index].items = items;

    const putData = items.map((item, index) => ({
      id: item._id,
      position: index,
    }));

    handleItemsPosition(putData, categoryId);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (
      result.source.droppableId === result.destinationdroppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }

    if (result.type === "column") {
      const reorderData = reorderCategory(
        menuData,
        result.source.index,
        result.destination.index
      );
      setMenuData(reorderData);
      return;
    }
    // sort in same category
    if (result.source.droppableId === result.destination.droppableId) {
      const reorderData = reorderItems(
        menuData,
        result.source.droppableId,
        result.source.index,
        result.destination.index
      );
      setMenuData(reorderData);
      return;
    }
    // sort with move
    const reorderData = reorderItemsWithMove(
      menuData,
      result.source.droppableId,
      result.source.index,
      result.destination.droppableId,
      result.destination.index
    );
    setMenuData(reorderData);
    return;
  };

  const handleDeleteItem = (menuItem) => {
    if (!window.confirm("Sind Sie sicher?")) return false;
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios
          .delete(
            `${endpoint}/menu/item/${businessId}/${menuItem.name}`,
            config
          )
          .then(
            (res) => {
              getMenuData();
            },
            (error) => {
              console.log(error);
            }
          );
      } catch (err) {
        console.error(err);
      }
    })();
  };

  const Category = ({ category, items, index }) => {
    return (
      <Draggable draggableId={category._id} index={index}>
        {(provided, snapshot) => (
          <CategoryContainer
            className="new-res-block"
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
          >
            <MenuCategory
              key={`menu-category-${category._id}`}
              handleVisibilityChange={handleVisibilityChange}
              handleDeleteItem={handleDeleteItem}
              handleEdit={handleEdit}
              category={category}
              dragHandleProps={provided.dragHandleProps}
              categoryItems={items}
              handleEditCategory={handleEditCategory}
              handleCategoryDelete={handleCategoryDelete}
            />
            <div
              className="new-res-block__bottom-block pointer"
              onClick={() => itemAdd(category._id)}
            >
              <img src="./img/new-product-new.svg" />
              <h4 className="new-res-block__main-header">Produkt hinzufügen</h4>
            </div>
          </CategoryContainer>
        )}
      </Draggable>
    );
  };

  const CategoriesList = React.memo(function CategoriesList({ data }) {
    return (
      <>
        {data &&
          data.map(({ category, items }, index) => (
            <Category
              category={category}
              items={items}
              index={index}
              key={category._id}
            />
          ))}
        <div className="new-category-container">
          <div className="new-category-block pointer">
            <input
              type="text"
              className="new-category-block__main-header"
              placeholder="Neue Kategorie eingeben..."
              ref={categoryInput}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value) {
                  addCategory(e.target.value);
                }
              }}
            />
          </div>
        </div>
      </>
    );
    return null;
  });

  return (
    <section className="main-section">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="list-category"
          direction="horizontal"
          type="column"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="list-category"
            >
              <CategoriesList data={menuData} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddItemForm
        visible={showEditForm}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        categories={menuData}
        item={editItem}
        handleDeleteItem={handleDeleteItem}
      />
    </section>
  );
};

export default React.memo(MenuPage);
