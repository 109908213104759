import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import FeedbackList from "./components/FeedbackList";
import FeedbackDetail from "./components/FeedbackDetail";
import MobileDetails from "./components/MobileDetails";

import "./Feedback.css";

const endpoint = process.env.REACT_APP_API_ORIGIN;

const FeedbackPage = ({ businessId, navbar }) => {
  const { getAccessTokenSilently } = useAuth0();
  // const [data, setData] = useState();
  const [feedbacks, setFeedbacks] = useState();
  const [chosenFeedback, setChosenFeedback] = useState({});
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showDetails, setShowDetails] = useState(false);
  const [feedbackCardsScroll, setFeedbackCardsScroll] = useState(0);
  const chatContentMain = useRef(null);
  const lastChatMessagesGroup = useRef(null);
  const chatListWrapper = useRef(null);
  const chosenFeedbackRef = useRef(null);
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState();

  const handleWindowResize = () => setWidth(window.innerWidth);

  const closeCard = () => {
    setShowDetails(false);
  };

  useEffect(() => {
    if (chatListWrapper.current) {
      chatListWrapper.current.scrollTo(0, feedbackCardsScroll);
    }
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    getFeedbacks();
  }, []);

  useEffect(() => {
    if (feedbacks) {
      setLoading(true);
    }
    if (feedbacks && feedbacks[0]) {
      setChosenFeedback(feedbacks[0]);
    }
  }, [feedbacks]);

  useEffect(() => {
    getChat();
  }, [chosenFeedback]);

  useEffect(() => {
    if (message) {
      setMessage("");
    }
    getChat();
  }, [messageSent]);

  const getChat = () => {
    if (chosenFeedback) {
      let session = chosenFeedback.session;
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          // getChat for the chosen feedback
          const res = await axios.get(
            `${endpoint}/feedback/chat/${session}`,
            config
          );
          const chat = res.data.data;
          setChat(chat);
          // console.log("chat: ", chat);
        } catch (err) {
          console.error(err);
        }
      })();
    }
  };

  const getFeedbacks = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios.get(
          `${endpoint}/feedback/${businessId}`,
          config
        );
        const resBusiness = await axios.get(
          `${endpoint}/business/information/${businessId}`,
          config
        );
        const isTest = resBusiness?.data?.data?.testBusiness;
        let feedbacks = res?.data?.data;
        if (!isTest) {
          feedbacks = feedbacks.filter((feedback) => {
            const feedbackDate = new Date(feedback.date);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            return feedbackDate < yesterday;
          });
        }
        feedbacks.sort((a, b) => (a.date > b.date ? -1 : 1));
        setFeedbacks(feedbacks);
      } catch (err) {
        setLoading(true);
      }
    })();
  };

  const getFeedbackImage = (question) => {
    switch (question) {
      case "good":
        return "./img/green-face.svg";
      case "ok":
        return "./img/yellow-face.svg";
      case "bad":
        return "./img/red-face.svg";
      default:
        return "./img/dash.svg ";
    }
  };

  const scrollHandler = (e) => {
    setFeedbackCardsScroll(e.target.scrollTop);
  };

  const sendMessage = (event) => {
    event.preventDefault();
    if (message) {
      let data = {
        session: chosenFeedback.session,
        user: "owner",
        localId: chosenFeedback.localId,
        message: message,
        isNewMessage: false,
      };
      setMessageSent(data);
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };

          const resp = await axios.put(
            endpoint + "/feedback/message",
            { data: data },
            config
          );
        } catch (err) {
          console.log(err);
        }
      })();
    }
  };

  const getDate = (date) => {
    const today = new Date();
    const feedbackDate = new Date(date);
    const dateToDisplay = `${("0" + feedbackDate.getDate()).slice(-2)}.${(
      "0" +
      (feedbackDate.getMonth() + 1)
    ).slice(-2)}.${feedbackDate.getFullYear().toString().substring(2)}`;
    if (
      feedbackDate.getDate() == today.getDate() &&
      feedbackDate.getMonth() == today.getMonth() &&
      feedbackDate.getFullYear() == today.getFullYear()
    ) {
      return "Heute";
    } else {
      return dateToDisplay;
    }
  };

  if (!loading) return null;

  if (width < 600 && showDetails) {
    return (
      <MobileDetails
        getFeedbackImage={getFeedbackImage}
        chosenFeedback={chosenFeedback}
        chatContentMain={chatContentMain}
        getDate={getDate}
        chat={chat}
        message={message}
        sendMessage={sendMessage}
        setMessage={setMessage}
        navbar={navbar}
        closeCard={closeCard}
      />
    );
  }
  navbar.current.style.display = "";
  return (
    <div id="remove-scroll-div">
      <div className="feedback">
        {/* left side of feedback page */}
        <div className="feedback__list-wrapper">
          <div
            className="feedback__list-section custom-scroll"
            onScroll={scrollHandler}
            ref={chatListWrapper}
          >
            <FeedbackList
              setChosenFeedback={setChosenFeedback}
              feedbacks={feedbacks}
              chosenFeedback={chosenFeedback}
              getDate={getDate}
              getFeedbackImage={getFeedbackImage}
              setShowDetails={setShowDetails}
            />
          </div>
        </div>
        {/* right side of feedback page */}
        {chosenFeedback && feedbacks && feedbacks.length > 0 && (
          <FeedbackDetail
            getFeedbackImage={getFeedbackImage}
            chosenFeedback={chosenFeedback}
            chatContentMain={chatContentMain}
            getDate={getDate}
            chat={chat}
            message={message}
            sendMessage={sendMessage}
            setMessage={setMessage}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(FeedbackPage);
