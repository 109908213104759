import { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";

import "./CustomerScreen.css";

const CustomerScreen = ({ businessId }) => {
  const [openOrders, setOpenOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  var io = require("socket.io-client");

  const endpoint = process.env.REACT_APP_API_ORIGIN;

  var socket = io.connect(endpoint);

  socket.on("connect", function (socket) {
    console.log("Connected!");
  });

  useEffect(() => {
    getOrdersFromServer();
  }, []);

  useEffect(() => {
    setOpenOrders(
      orders.filter((order) => !order.delivered && order.status === "open")
    );
    setReadyOrders(
      orders.filter((order) => !order.delivered && order.status === "ready")
    );
  }, [orders]);

  useEffect(() => {
    socket.on("newOrdersToService", getOrdersFromServer);
  }, []);

  async function getOrdersFromServer() {
    try {
      const token = await getAccessTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const newOrders = await axios.get(
        endpoint + "/orders/orders-list/" + businessId,
        config
      );
      // console.log("New orders data data ", newOrders.data.data);
      let newOrderData = newOrders.data.data;
      if (newOrderData.length > orders.length) {
        // play();
      }
      setOrders(newOrderData);
    } catch (err) {
      console.error(err);
    }
  }

  return readyOrders[0] ? (
    <div className="screen">
      <div className="ready-orders">
        {readyOrders.map((order) => (
          <>
            <div className="customer-name">
              <div className="check-icon-wrapper">
                <FontAwesomeIcon
                  className="check-icon"
                  icon={faCheck}
                  color="white"
                />
              </div>
              <p>{order.customer.firstName}</p>
            </div>
          </>
        ))}
      </div>
    </div>
  ) : (
    <div className="screen blank"></div>
  );
};

export default CustomerScreen;
