import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import DisplayQrCode from "./components/QrCode";
import Button from "../../components/smaller/button/Button";
import "./QrCode.css";

// const endpoint = process.env.REACT_APP_API_ORIGIN;

const QrCodePage = ({ businessId }) => {
  // useEffect(() => {}, []);

  const downloadQR = () => {
    const canvas = document.getElementById("QRCodeImage");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "feedoo-qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // const functionName = () => {
  //   if ("") {
  //     (async () => {
  //       try {
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     })();
  //   }
  // };

  return (
    <div className="container-fluid p-0">
      <div className="QrCode row">
        <div className="QrCode-content col-sm-6">
          <div className="QR-text">
            Bitte laden den QR-code runter und drücken Sie den aus. Er sollte
            für Ihre Gäste gut sichtbar sein.
          </div>
          <div className="QrCode-block">
            <DisplayQrCode businessId={businessId} />
          </div>

          <div className="buttons-QR">
            <div className="form-group buttons-primary">
              <a onClick={downloadQR} className="btn button-primary">
                Runterladen
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(QrCodePage);
