// https://github.com/m6un/insta-chat/blob/master/client/src/Components/Chat/Chat.js

import React from "react";

import "./Input.css";
import "../../../pages/Feedback/Feedback.css";

const Input = ({ setMessage, sendMessage, message }) => (
  <form className="form">
    <input
      className="input input-feedback"
      type="text"
      placeholder="Antwort eingeben..."
      value={message}
      onChange={({ target: { value } }) => setMessage(value)}
      onKeyPress={(event) =>
        event.key === "Enter" ? sendMessage(event) : null
      }
    />
    {/* <button className="sendButton" onClick={(e) => sendMessage(e)}> */}
    <img
      onClick={(e) => sendMessage(e)}
      className="send-image pointer"
      src="/img/send-message.svg"
      alt="image"
    />
    {/* </button> */}
  </form>
);

export default Input;
