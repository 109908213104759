const InfoTab = ({
  isSelected,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  if (!isSelected) return null;
  return (
    <div className="top-block">
      <div className="top-block__top">
        <div className="top-block__top__left">
          <label htmlFor="name-input">Überschrift*</label>
          <input
            type="text"
            id="name-input"
            name="info.title"
            className={
              errors?.info?.title ? "common-input error-border" : "common-input"
            }
            placeholder="z. B. Willkommen!"
            value={values?.info?.title}
            onChange={handleChange}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />
          {errors?.info?.title ? (
            <div className="error-block">
              <span className="error-message">{errors.info.title}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="top-block__bottom">
        <label htmlFor="textarea-input">Infotext</label>
        <textarea
          name="info.text"
          className="top-block__bottom__mid common-input"
          id="textarea-input"
          value={values?.info?.text}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        ></textarea>
        <p className="top-block__bottom__bottom"></p>
      </div>
    </div>
  );
};

export default InfoTab;
