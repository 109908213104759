import React, { useEffect } from "react";
import FeedbackCard from "./FeedbackCard";
import "./../Feedback.css";

const FeedbackList = ({
  setChosenFeedback,
  chosenFeedback,
  feedbacks,
  getDate,
  getFeedbackImage,
  setShowDetails,
}) => {
  return (
    <>
      {/* <div className="feedback__list-wrapper">
    <div className="feedback__list-section custom-scroll"> */}
      {feedbacks && feedbacks.length > 0
        ? feedbacks.map((element, i) => {
            return (
              <div key={i}>
                <FeedbackCard
                  setChosenFeedback={setChosenFeedback}
                  feedbackContent={element}
                  chosenFeedback={chosenFeedback}
                  getDate={getDate}
                  getFeedbackImage={getFeedbackImage}
                  setShowDetails={setShowDetails}
                />
              </div>
            );
          })
        : "Bald bekommen Sie Feedback!"}
      {/* </div>
    </div> */}
    </>
  );
};
export default FeedbackList;
