import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Formik } from "formik";
import axios from "axios";
import { Tabs, defaultInitialValues } from "./constant";
import InfoTab from "./Info";
import LinksTab from "./Links";
import OpenTab from "./Open";
import BusinessTab from "./Business";
import "./Information.css";

const endpoint = process.env.REACT_APP_API_ORIGIN;

const InformationPage = ({ businessId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [tab, setTab] = useState(Tabs.INFO);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(true);
    }
  }, [data]);

  const getData = () => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios.get(
          `${endpoint}/business/information/${businessId}`,
          config
        );
        const information = res?.data?.data?.information;
        let links = {};
        (information?.links || []).forEach((link) => {
          links[link.name] = link.url;
        });
        const business = {
          businessName: res?.data?.data?.businessName,
          address: res?.data?.data?.address,
          country: res?.data?.data?.country,
          phone: links["phone"],
          email: links["email"],
        };
        let openingTimes = {};
        (res?.data?.data?.openingTimes || []).forEach((time) => {
          openingTimes[time.period] = {
            isOpen: true,
            from: time.from,
            to: time.to,
          };
        });
        setData({
          info: {
            text: information?.infoText || "",
            title: information?.infoHeadline || "",
          },
          business: { ...defaultInitialValues.business, ...business },
          links: { ...defaultInitialValues.links, ...links },
          openingTimes: {
            ...defaultInitialValues.openingTimes,
            ...openingTimes,
          },
        });
      } catch (err) {
        setLoading(true);
      }
    })();
  };

  const handleSubmit = (values, setSubmitting) => {
    const insertData = {
      information: {
        infoText: values.info.text,
        infoHeadline: values.info.title,
        links: [],
      },
      openingTimes: [],
      ...values.business,
    };
    // prep links for insert
    Object.keys(values.links).forEach((name) => {
      if (name === "email" || name === "phone") {
        insertData.information.links.push({
          name,
          url: values.business[name],
        });
      } else {
        insertData.information.links.push({
          name,
          url: values.links[name],
        });
      }
    });

    // prep open times
    Object.keys(values.openingTimes).forEach((period) => {
      if (!values.openingTimes[period].isOpen) return;
      insertData.openingTimes.push({
        period,
        from: values.openingTimes[period].from,
        to: values.openingTimes[period].to,
      });
    });
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const res = await axios
          .put(
            `${endpoint}/business/${businessId}`,
            {
              data: {
                ...insertData,
              },
            },
            config
          )
          .then(
            (res) => {
              setSaved(true);
              setSubmitting(false);
            },
            (error) => {
              setSubmitting(false);
            }
          );
      } catch (err) {
        setSubmitting(false);
      }
    })();
  };

  const getContent = (name, formikData) => {
    switch (name) {
      case Tabs.INFO:
        return <InfoTab key={name} isSelected={name === tab} {...formikData} />;
      case Tabs.LINKS:
        return (
          <LinksTab key={name} isSelected={name === tab} {...formikData} />
        );
      case Tabs.OPEN:
        return <OpenTab key={name} isSelected={name === tab} {...formikData} />;
      case Tabs.BUSINESS:
        return (
          <BusinessTab key={name} isSelected={name === tab} {...formikData} />
        );
      default:
        return null;
    }
  };

  if (!loading) return null;

  return (
    <div>
      <Formik
        initialValues={data}
        validate={(values) => {
          let errors = {};

          if (!values.info.title) {
            errors = { info: {} };
            errors.info.title = "Bitte Überschrift eingeben";
            return errors;
          }

          if (!values.business.businessName) {
            errors = { business: {} };
            errors.business.businessName = "Bitte Geschäftsname eingeben";
            return errors;
          }

          return {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSaved(false);
          setSubmitting(true);
          handleSubmit(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div className="info-block">
            <form onSubmit={handleSubmit}>
              <div className="information-component">
                <div className="result-block information-result-block">
                  <div className="information-window">
                    <div className="top-links">
                      {Object.keys(Tabs).map((name) => (
                        <a
                          className={`top-link ${
                            tab === Tabs[name] ? "active" : ""
                          }`}
                          onClick={() => setTab(Tabs[name])}
                          key={`links_${name}`}
                        >
                          {Tabs[name]}
                        </a>
                      ))}
                    </div>
                    {Object.keys(Tabs).map((name) =>
                      getContent(Tabs[name], {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      })
                    )}
                    <div className="bottom-block">
                      <button
                        className={"right-button pointer width100"}
                        disabled={isSubmitting}
                        type="submit"
                      >
                        <span className="bold">
                          {saved ? "+ Speichern" : "Speichern"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(InformationPage);
