import React from "react";
import { Formik, Form, Field, ErrorMessage, Dropdown } from "formik";
import * as Yup from "yup";
import Options from "./Options";
import "./Setup.css";

const Setup = ({ user, visible, handleSubmitSetup }) => {
  const businessTypes = [
    { value: "restaurant", label: "Restaurant" },
    { value: "coffee", label: "Café" },
    { value: "bar", label: "Bar" },
    { value: "education", label: "Bildung" },
    { value: "shop", label: "Einkaufsladen" },
    { value: "sport", label: "Sport" },
    { value: "culture", label: "Kultur" },
    { value: "other", label: "Nicht in der Liste" },
  ];

  return (
    <>
      {visible && (
        <div className="setup-overlay">
          <Formik
            initialValues={{
              businessName: "",
              businessType: "restaurant",
              address: "",
              // firstName: "",
              // lastName: "",
              email: user.email,
              // password: "",
              // confirmPassword: "",
            }}
            validationSchema={Yup.object().shape({
              businessName: Yup.string().required(
                "Bitte Geschäftsname eingeben"
              ),
              businessType: Yup.string().required(
                "Bitte Geschäftstyp eingeben"
              ),
              address: Yup.string().required("Bitte Adresse eingeben"),

              // firstName: Yup.string().required("Bitte Vorname eingeben"),
              // lastName: Yup.string().required("Bitte Nachname eingeben"),
              email: Yup.string()
                .email("Email is nicht korrekt")
                .required("Bitte Email eingeben"),
              // password: Yup.string()
              //   .min(6, "Password must be at least 6 characters")
              //   .required("Password is required"),
              // confirmPassword: Yup.string()
              //   .oneOf([Yup.ref("password"), null], "Passwords must match")
              //   .required("Confirm Password is required"),
            })}
            onSubmit={(fields) => {
              // setFields(fields);
              handleSubmitSetup(fields);
            }}
            render={({ errors, status, touched }) => (
              <Form>
                <div className="setup-logo">
                  <img src="/img/logo_face.png" alt="logo" />
                </div>
                <div className="form-title">
                  Gleich starten Sie, <br /> bitte geben Sie noch ein paar Infos
                  ein.
                </div>

                <div className="form-group">
                  <label htmlFor="businessType">Geschäftstyp*</label>
                  <div>
                    <Field
                      as="select"
                      name="businessType"
                      type="text"
                      placeholder="bitte auswählen"
                      className={
                        "form-control" +
                        (errors.businessType && touched.businessType
                          ? " is-invalid"
                          : "")
                      }
                    >
                      <Options options={businessTypes} />
                    </Field>
                  </div>
                  <ErrorMessage
                    name="businessType"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="businessName">Geschäftsname*</label>
                  <Field
                    name="businessName"
                    type="text"
                    placeholder="z.B. Trattoria"
                    className={
                      "form-control" +
                      (errors.businessName && touched.businessName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="businessName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Geschäftsemail*</label>
                  <Field
                    name="email"
                    placeholder="z.B. meinemail@gmail.com"
                    type="email"
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="address">Geschäftsadresse*</label>
                  <Field
                    name="address"
                    type="text"
                    placeholder="z.B. Hauptstrasse 21, Berlin 10267"
                    className={
                      "form-control" +
                      (errors.address && touched.address ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                {/* <div className="form-group">
              <label htmlFor="website">Website</label>
              <Field
                name="website"
                type="text"
                placeholder="z.B. https://meinladen.com"
                className={
                  "form-control" +
                  (errors.businessName && touched.businessName
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="website"
                component="div"
                className="invalid-feedback"
              />
            </div> */}

                {/* <div className="form-group">
              <label htmlFor="firstName">Ihre Vorname</label>
              <Field
                name="firstName"
                type="text"
                className={
                  "form-control" +
                  (errors.firstName && touched.firstName ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Ihre Nachname</label>
              <Field
                name="lastName"
                type="text"
                className={
                  "form-control" +
                  (errors.lastName && touched.lastName ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="invalid-feedback"
              />
            </div> */}

                {/* <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field
                name="password"
                type="password"
                className={
                  "form-control" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div> */}
                {/* <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <Field
                name="confirmPassword"
                type="password"
                className={
                  "form-control" +
                  (errors.confirmPassword && touched.confirmPassword
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="invalid-feedback"
              />
            </div>*/}
                <div className="form-group buttons-setup">
                  <button type="submit" className="btn button-setup">
                    Jetzt Starten
                  </button>

                  {/* <button type="reset" className="btn btn-secondary">
                    Alle Daten löschen
                  </button> */}
                </div>
              </Form>
            )}
          />
          {/* </Formik> */}
        </div>
      )}
    </>
  );
};

export default React.memo(Setup);
