const Feature = ({ name, features, toggleOff, children }) => {
  // console.log("in the feature component");
  // console.log("features: ", features, "toggleOff: ", toggleOff);

  if (!features) {
    return null;
  } else {
    const feature = features.find((feature) => feature.name === name);
    // console.log(feature);

    if (toggleOff) {
      if (!(feature && feature.active)) {
        // console.log("returns children");
        return children;
      }
      // console.log("doesn't return children");
      return null;
    } else {
      if (feature && feature.active) {
        // console.log("returns children");
        return children;
      }
      // console.log("doesn't return children");
      return null;
    }
  }
};
export default Feature;
