import React, { useEffect } from "react";
import FeedbackDetail from "./FeedbackDetail";
import "./../Feedback.css";

const MobileDetails = ({
  closeCard,
  chatContentMain,
  chosenFeedback,
  getFeedbackImage,
  navbar,
  getDate,
  chat,
  message,
  sendMessage,
  setMessage,
}) => {
  useEffect(() => {
    window.onpopstate = (e) => {
      navbar.current.style.display = "";
    };
  });
  navbar.current.style.display = "none";
  return (
    <div className="scroll-details">
      <div onClick={closeCard}>
        {/* <img
          src="../../img/chat-arrow-back.svg"
          alt=""
          className="back-button__image"
        /> */}
        <div className="circle">
          <div className="closebtn">&times;</div>
        </div>
      </div>
      <FeedbackDetail
        getFeedbackImage={getFeedbackImage}
        chosenFeedback={chosenFeedback}
        chatContentMain={chatContentMain}
        getDate={getDate}
        chat={chat}
        message={message}
        sendMessage={sendMessage}
        setMessage={setMessage}
      />
    </div>
  );
};

export default MobileDetails;

{
  /* <div
className="feedback__detail-main custom-scroll"
ref={chatContentMain}
>
<div className="feedback-detail__item">
  {chosenFeedback.questions && chosenFeedback.questions.length > 0 ? (
    chosenFeedback.questions.map((question) => {
      return (
        <FeedbackDetailsNew
          feedbackContent={question}
          getFeedbackImage={getFeedbackImage}
        />
      );
    })
  ) : (
    <FeedbackDetailsOld
      feedbackContent={chosenFeedback}
      getFeedbackImage={getFeedbackImage}
    />
  )}
  <div className="chat__message-time">
    {getDate(chosenFeedback.date)}
  </div>
</div>
</div>
<div className="feedback__detail-input">
{chosenFeedback.isMail && (
  <div className="message-input-wrapper">
    <textarea
      className="message-input"
      placeholder="Antwort eingeben"
    ></textarea>
    <img className="send-image pointer" src="./img/send-message.svg" />
  </div>
)}
</div> */
}
