import React from "react";

const FeedbackDetailsNew = ({ question, getFeedbackImage }) => {
  return (
    <div className="service-item__item">
      <div className="service-item__item__service-top">
        <img
          className="service-item__item__service-image"
          src={getFeedbackImage(question.result)}
        />
        <h3 className="service-item__item__service-header">{question.name}</h3>
      </div>
      <p className="service-item__item__service-content">{question.comment}</p>
    </div>
  );
};

export default FeedbackDetailsNew;
