import React from "react";

const Chat = ({ chat, getDate }) => {
  return (
    <div className="chat__list">
      {chat
        .sort(function (a, b) {
          return new Date(a.time) - new Date(b.time);
        })
        .map((m, i) => (
          <div className="messages">
            <div
              className={`message ${m.user === "owner" ? "sender" : ""}`}
              key={i}
            >
              <div className="chat__message-time">{getDate(m.time)}</div>
              <p className="chat__message-content">{m.message}</p>
            </div>
          </div>
        ))}
    </div>
  );
};
export default Chat;
