import { useState, useEffect } from "react";
const utils = require("../../utils.js");
const fixFormat = utils.fixFormat;

const MenuItem = ({
  menuItem,
  handleVisibilityChange,
  handleEdit,
  handleDeleteItem,
}) => {
  const { _id: itemId, name, priceInEuros, available, description } = menuItem;
  const [itemAvailability, setItemAvailability] = useState(available);

  return (
    <>
      <p className="new-res-block__mid-block__product-card__top-text">{name}</p>
      <div className="new-res-block__mid-block__product-card__bottom-block">
        <p className="new-res-block__mid-block__product-card__bottom-block__left-text">
          € {fixFormat(priceInEuros)}
        </p>
        <div className="new-res-block__mid-block__product-card__bottom-block__buttons-block">
          <img
            src="./img/delete-new.svg"
            onClick={() => handleDeleteItem(menuItem)}
          />
          <img
            src="./img/edit.svg"
            onClick={() => {
              handleEdit(menuItem);
            }}
          />
          <img
            src={`${
              itemAvailability ? "./img/eye-opened.svg" : "./img/eye-closed.svg"
            }`}
            onClick={() => {
              setItemAvailability(!itemAvailability);
              handleVisibilityChange(itemId, available);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MenuItem;
