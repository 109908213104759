import "./Order.css";
import Item from "./Item";
import Moment from "react-moment";
import "moment-timezone";
import axios from "axios";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const utils = require("../../utils.js");
const fixFormat = utils.fixFormat;

const Order = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  let totalPrice = props.order.totalPriceInEuros;
  props.order.orderedItems.map((item) => {
    if (item.amountOfCups) {
      totalPrice = totalPrice + item.amountOfCups * 0.1;
    }
  });

  async function changeOrderStateHandler(orderId) {
    const token = await getAccessTokenSilently();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const status = await props.order.status;

    let newStatus;
    switch (status) {
      case "open":
        newStatus = "ready";
        break;
      case "ready":
        newStatus = "closed";
        break;
    }

    const endpoint = process.env.REACT_APP_API_ORIGIN;
    axios
      .put(
        endpoint +
          "/orders/orders-list/" +
          props.businessId +
          "/" +
          orderId +
          "/" +
          newStatus,
        "",
        config
      )
      .then((response) => {
        console.log(`open response: ${response}`);
      })
      .catch();
  }

  useEffect(() => {}, [props.order.ready, props.order]);

  return (
    <div className={`order ready-${props.order.ready}`}>
      <div className="order-header">
        <h3>
          <b>
            <Moment format="HH:mm">{new Date(props.order.orderedAt)}</Moment>
            &nbsp;Uhr
          </b>
          &nbsp;|&nbsp;von&nbsp;{props.order.customer.firstName}
        </h3>
        <h3>
          Summe: <b>€ {fixFormat(totalPrice)} </b>
        </h3>
      </div>
      {props.order.orderedItems.map((item) => (
        <Item key={item._id} item={item} />
      ))}
      <div className="order-footer">
        <div className="order-comment">
          {props.order.comment && (
            <p>
              <b>Kommentar: </b>
              {props.order.comment}
            </p>
          )}
        </div>
        <button
          className={`ready-${props.order.status === "ready"}`}
          onClick={() => changeOrderStateHandler(props.order._id)}
        >
          {props.order.status === "open" ? (
            <span>Abholbereit</span>
          ) : (
            <span>Bestellung entfernen</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Order;
