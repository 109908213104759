// <a target="_blank" href="https://icons8.com/icon/81197/coffee-beans">Coffee Beans</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>

export function getBackground(businessType) {
  let img1 = "";
  let img2 = "";
  let img1Pos = "";
  let img2Pos = "";
  let background = "";
  let backgroundRepeat = "";

  switch (businessType) {
    case "restaurant":
      img1 =
        "https://ik.imagekit.io/jgjpy5qr3r5i/bg_left_part_4HbOPQT9g.png?updatedAt=1638799367337";

      img2 =
        "https://ik.imagekit.io/jgjpy5qr3r5i/bg_right_part_72yOia3qw.png?updatedAt=1638799350878";
      img1Pos = "top left";
      img2Pos = "top right";
      backgroundRepeat = "no-repeat, no-repeat, repeat";
      break;
    case "coffee":
      // img1 =
      //   "https://ik.imagekit.io/jgjpy5qr3r5i/Background_coffee_left_small_jGtkYHXGs.png?updatedAt=1638896311644";
      img1 =
        "https://ik.imagekit.io/jgjpy5qr3r5i/bg-coffee-left-bean_UTBsDQj_s.png?updatedAt=1638917069848";
      img2 =
        "https://ik.imagekit.io/jgjpy5qr3r5i/bg-coffee-right-bean__FHs_5Sj502.png?updatedAt=1638917079678";
      img1Pos = "top left";
      img2Pos = "top right";
      backgroundRepeat = "repeat-y, repeat-y, repeat";
      break;
    default:
      img1 = false;
      img2 = false;
  }
  if (img1) {
    background = `background-color : #fff;
      background-image: url(${img1}),url(${img2});
      background-repeat: ${backgroundRepeat};
      background-position: ${img1Pos}, ${img2Pos};
      height: 100%;
      background-color: #5c5a5a3b`;
  } else {
    background = `
      backgroundColor: #5c5a5a3b
      `;
  }

  return background;
}
