import "./Information.css";
const LinksTab = ({
  isSelected,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  if (!isSelected) return null;
  return (
    <div className="left-right-block">
      <div className="left-block">
        <label htmlFor="website-input">Website</label>
        <input
          name="links.website"
          type="text"
          id="website-input"
          className="common-input"
          placeholder="z. B. https://www.myfeedoo.de"
          value={values.links.website}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <label htmlFor="facebook-input">Facebook</label>
        <input
          name="links.facebook"
          type="text"
          id="facebook-input"
          className="common-input"
          placeholder="z. B. https://www.facebook.com"
          value={values.links.facebook}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <label htmlFor="instagram-input">Instagram</label>
        <input
          name="links.instagram"
          type="text"
          id="instagram-input"
          className="common-input"
          placeholder="z. B. https://www.instagram.com"
          value={values.links.instagram}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>

      <div className="right-block">
        <label htmlFor="corona-input">Corona warn-app</label>
        <input
          name="links.coronawarn"
          type="text"
          id="corona-input"
          className="common-input"
          placeholder="z. B. https://e.coronawarn.de"
          value={values.links.coronawarn}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <label htmlFor="luca-input">Luca</label>
        <input
          name="links.luca"
          type="text"
          id="luca-input"
          className="common-input"
          placeholder="z. B. https://www.app.luca.app.de/webapp/09876.de"
          value={values.links.luca}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <label htmlFor="corona-anmeldung-input">Andere Corona Anmeldung</label>
        <input
          name="links.corona_anmeldung"
          type="text"
          id="corona-anmeldung-input"
          className="common-input"
          placeholder="z. B. https://www.corona.de"
          value={values.links.corona_anmeldung}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default LinksTab;
