import React from "react";
import FeedbackDetailsNew from "./FeedbackDetailsNew";
import FeedbackDetailsOld from "./FeedbackDetailsOld";
import "./../Feedback.css";

const FeedbackDetailsAll = ({
  chosenFeedback,
  getFeedbackImage,
  chatContentMain,
  getDate,
}) => {
  return (
    <div className="feedback-detail__item">
      {chosenFeedback &&
      chosenFeedback.questions &&
      chosenFeedback.questions.length > 0 ? (
        chosenFeedback.questions.map((question, i) => {
          return (
            <FeedbackDetailsNew
              key={i}
              question={question}
              getFeedbackImage={getFeedbackImage}
            />
          );
        })
      ) : (
        <FeedbackDetailsOld
          chosenFeedback={chosenFeedback}
          getFeedbackImage={getFeedbackImage}
        />
      )}
      <div className="chat__message-time">{getDate(chosenFeedback.date)}</div>
    </div>
  );
};

export default FeedbackDetailsAll;
