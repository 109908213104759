import "./Item.css";
import { useEffect } from "react";
const utils = require("../../utils.js");
const fixFormat = utils.fixFormat;

const Item = (props) => {
  // const [extraPrice, setItemAvailability] = useState();

  // useEffect(() => {
  //   props.item.extras && console.log("props.item: ", props.item);
  // }, [props]);

  return (
    <div className="item">
      <div className="col2">
        <p>
          {props.item.name}
          {props.item.extras && (
            <span className="extras">
              {props.item.extras.map(
                (extra) =>
                  extra.name !== "Normal" &&
                  extra.name !== "Standard" && <span> +{extra.name}</span>
              )}
            </span>
          )}
        </p>
      </div>
      <div className="col3">
        <p>
          €{" "}
          {props.item.totalExtraPriceInEuros
            ? fixFormat(
                props.item.priceInEuros + props.item.totalExtraPriceInEuros
              )
            : fixFormat(props.item.priceInEuros)}
        </p>
      </div>
    </div>
  );
};

export default Item;
