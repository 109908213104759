import React, { useRef } from "react";

const FeedbackCard = ({
  setChosenFeedback,
  chosenFeedback,
  feedbackContent,
  getDate,
  getFeedbackImage,
  setShowDetails,
}) => {
  const chosenFeedbackRef = useRef();

  const chooseFeedback = (feedback) => {
    setChosenFeedback(feedback);
    setShowDetails(true);
  };
  return (
    <div
      onClick={() => chooseFeedback(feedbackContent)}
      className={`chat__list__item pointer ${
        feedbackContent._id === chosenFeedback._id ? "chosen" : ""
      }`}
      key={feedbackContent._id}
    >
      <div className="chat__list__item-head">
        <p className="chat__list__item-head-left">
          {getDate(feedbackContent.date)}
        </p>
        <div className="chat__list__item-head-right">
          {feedbackContent.isMail && <img src="./img/question-answer.svg" />}
          {feedbackContent.isAnyComment && <img src="./img/comment.svg" />}
        </div>
      </div>
      <div className="chat__list__item-body">
        {feedbackContent.questions.length > 0 ? (
          feedbackContent.questions.map((question) => {
            return (
              <div className="chat__list__item-body__item" key={question._id}>
                <img src={getFeedbackImage(question.result)} />
                <p>{question.name}</p>
              </div>
            );
          })
        ) : (
          <>
            <div className="chat__list__item-body__item">
              <img src={getFeedbackImage(feedbackContent.start)} />
              <p>Allgemein</p>
            </div>
            <div className="chat__list__item-body__item">
              <img src={getFeedbackImage(feedbackContent.question1)} />
              <p>Angebot</p>
            </div>
            <div className="chat__list__item-body__item">
              <img src={getFeedbackImage(feedbackContent.question2)} />
              <p>Service</p>
            </div>
            <div className="chat__list__item-body__item">
              <img src={getFeedbackImage(feedbackContent.question3)} />
              <p>Hygiene</p>
            </div>
            <div className="chat__list__item-body__item">
              <img src={getFeedbackImage(feedbackContent.question4)} />
              <p>Ambiente</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackCard;
