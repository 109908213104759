import React from "react";

const Options = ({ options }) => {
  return (
    <>
      {options.map((item, id) => {
        return (
          <option key={id} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </>
  );
};

export default React.memo(Options);
