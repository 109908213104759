import "./Button.css";

const Button = ({ text }) => {
  return (
    <div className="form-group buttons-primary">
      <button type="submit" className="btn button-primary">
        {text}
      </button>
    </div>
  );
};

export default Button;
