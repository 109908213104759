export const Tabs = {
  INFO: "Aktuelle Informationen",
  LINKS: "Links",
  OPEN: "Öffnungszeiten",
  BUSINESS: "Geschäftsdaten",
};

export const defaultInitialValues = {
  info: {
    title: "",
    text: "",
  },
  links: {
    website: "",
    facebook: "",
    instagram: "",
    coronawarn: "",
    luca: "",
    corona_anmeldung: "",
    phone: "",
    email: "",
  },
  business: {
    addressHouseNumber: "",
    address: "",
    country: "",
    businessName: "",
    country: "",
    businessName: "",
  },
  openingTimes: {
    monday: {
      isOpen: false,
      from: "",
      to: "",
    },
    tuesday: {
      isOpen: false,
      from: "",
      to: "",
    },
    wednesday: {
      isOpen: false,
      from: "",
      to: "",
    },
    thursday: {
      isOpen: false,
      from: "",
      to: "",
    },
    friday: {
      isOpen: false,
      from: "",
      to: "",
    },
    saturday: {
      isOpen: false,
      from: "",
      to: "",
    },
    sunday: {
      isOpen: false,
      from: "",
      to: "",
    },
  },
};

export const weekDays = {
  monday: "Montag",
  tuesday: "Dienstag",
  wednesday: "Mittwoch",
  thursday: "Donnerstag",
  friday: "Freitag",
  saturday: "Samstag",
  sunday: "Sonntag",
};
