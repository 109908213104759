import React, { useEffect } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatList from "./ChatList";
import Input from "../../../components/smaller/Input/Input";
import FeedbackDetailsAll from "./FeedbackDetailsAll";
import "./../Feedback.css";

const FeedbackDetail = ({
  getFeedbackImage,
  chosenFeedback,
  chatContentMain,
  getDate,
  chat,
  message,
  sendMessage,
  setMessage,
  feedbacks,
}) => {
  return (
    <div className="feedback__detail-section custom-scroll">
      <ScrollToBottom
        followButtonClassName="scrolldown-button"
        initialScrollBehavior="auto"
        // https://stackblitz.com/edit/scroll-to-bottom-react-chat-app?file=src%2FApp.js
        ref={chatContentMain}

        // onClick={chatContentMainClickHandler}
        // onScroll={checkScroll}
      >
        <div className="feedback__detail-main">
          <div>
            <FeedbackDetailsAll
              getFeedbackImage={getFeedbackImage}
              chosenFeedback={chosenFeedback}
              chatContentMain={chatContentMain}
              getDate={getDate}
            ></FeedbackDetailsAll>
          </div>
          <ChatList chat={chat} getDate={getDate}></ChatList>
        </div>
      </ScrollToBottom>
      <div className="feedback__detail-input">
        {chosenFeedback.isMail && (
          <Input
            message={message}
            sendMessage={sendMessage}
            setMessage={setMessage}
          ></Input>
        )}
      </div>
    </div>
  );
};
export default FeedbackDetail;
