import React from "react";
import QrCode from "qrcode.react";

const DisplayQrCode = ({ businessId }) => {
  const value = "https://guest.myfeedoo.de/" + businessId;
  const realSize = 256;
  const screenWidth = 256;
  const screenHeight = 256;
  const imageSettings = {
    src: "/img/logo_face.png",
    height: "15",
    width: "15",
    excavate: true,
  };
  return (
    <div className="QrCode-image">
      <QrCode
        id="QRCodeImage"
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="L"
        size={realSize}
        includeMargin={true}
        style={{ width: screenWidth, height: screenHeight }}
        value={value}
        // imageSettings={imageSettings}
      />
    </div>
  );
};
export default DisplayQrCode;
