import React from "react";

const FeedbackDetailsOld = ({ chosenFeedback, getFeedbackImage }) => {
  return (
    <>
      <div className="service-item__item">
        <div className="service-item__item__service-top">
          <img
            className="service-item__item__service-image"
            src={getFeedbackImage(chosenFeedback.start)}
          />
          <h3 className="service-item__item__service-header">Allgemein</h3>
        </div>
        <p className="service-item__item__service-content">
          {chosenFeedback.comment}
        </p>
      </div>
      <div className="service-item__item">
        <div className="service-item__item__service-top">
          <img
            className="service-item__item__service-image"
            src={getFeedbackImage(chosenFeedback.question1)}
          />
          <h3 className="service-item__item__service-header">Angebot</h3>
        </div>
        <p className="service-item__item__service-content">
          {chosenFeedback.question1Comment}
        </p>
      </div>
      <div className="service-item__item">
        <div className="service-item__item__service-top">
          <img
            className="service-item__item__service-image"
            src={getFeedbackImage(chosenFeedback.question2)}
          />
          <h3 className="service-item__item__service-header">Service</h3>
        </div>
        <p className="service-item__item__service-content">
          {chosenFeedback.question2Comment}
        </p>
      </div>
      <div className="service-item__item">
        <div className="service-item__item__service-top">
          <img
            className="service-item__item__service-image"
            src={getFeedbackImage(chosenFeedback.question3)}
          />
          <h3 className="service-item__item__service-header">Hygiene</h3>
        </div>
        <p className="service-item__item__service-content">
          {chosenFeedback.question3Comment}
        </p>
      </div>
      <div className="service-item__item">
        <div className="service-item__item__service-top">
          <img
            className="service-item__item__service-image"
            src={getFeedbackImage(chosenFeedback.question4)}
          />
          <h3 className="service-item__item__service-header">Ambiente</h3>
        </div>
        <p className="service-item__item__service-content">
          {chosenFeedback.question4Comment}
        </p>
      </div>
    </>
  );
};

export default FeedbackDetailsOld;
