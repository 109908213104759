import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import MenuItem from "./Item";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ItemContainer = styled.div`
  background-color: ${(props) => (props.isDragging ? "lightgreen" : "")};
`;

const ItemListContainer = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.isDraggingOver ? "#eeeeee" : "inherit"};
`;

const MenuCategory = ({
  category,
  categoryItems,
  handleVisibilityChange,
  handleEdit,
  handleEditCategory,
  handleDeleteItem,
  dragHandleProps,
  handleCategoryDelete,
}) => {
  const { _id: categoryId, name: categoryName } = category;
  const [editForm, showEditForm] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (formRef.current && !formRef.current.contains(event.target)) {
        showEditForm(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);

  const Item = ({ item, index }) => {
    return (
      <Draggable draggableId={item._id} index={index}>
        {(provided, snapshot) => (
          <ItemContainer
            className="new-res-block__mid-block__product-card pointer"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
          >
            <MenuItem
              menuItem={item}
              handleVisibilityChange={handleVisibilityChange}
              handleEdit={handleEdit}
              handleDeleteItem={handleDeleteItem}
            />
          </ItemContainer>
        )}
      </Draggable>
    );
  };

  const ItemList = React.memo(function ItemList({ items }) {
    return items.map((item, index) => (
      <Item item={item} index={index} key={item._id} />
    ));
  });

  return (
    <>
      <div className="new-res-block__top-block" {...dragHandleProps}>
        {editForm ? (
          <input
            ref={formRef}
            type="text"
            className="new-category-block__main-header"
            defaultValue={categoryName}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value) {
                handleEditCategory({
                  ...category,
                  name: e.target.value,
                });
                showEditForm(false);
              }
            }}
          />
        ) : (
          <div className="new-res-block__main-header-container">
            <h4
              className="new-res-block__main-header"
              onClick={() => showEditForm(true)}
            >
              {categoryName}
            </h4>
            <img
              src="./img/delete-new.svg"
              onClick={() => handleCategoryDelete(categoryId)}
            />
          </div>
        )}
      </div>
      <Droppable droppableId={categoryId} type="item">
        {(provided, snapshot) => (
          <ItemListContainer
            className="new-res-block__mid-block"
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {categoryItems?.length > 0 ? (
              <ItemList items={categoryItems} />
            ) : (
              <p className="empty">Füge das erste Produkt hinzu ...</p>
            )}
            {provided.placeholder}
          </ItemListContainer>
        )}
      </Droppable>
    </>
  );
  return null;
};

export default MenuCategory;
