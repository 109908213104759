import React, { useState, useEffect } from "react";
import { useLocation, Link, NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faBars } from "@fortawesome/free-solid-svg-icons";
import LogoutButton from "./LogoutButton";
import logo from "../../img/myfeedo-logo-primary_color.png";
// import MainNav from "./main-nav";
// import { Navbar, Nav, Link } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
import Feature from "../../util/feature";
import "./Navbar.css";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

let dropdownItems = [
  // { to: "/Speisekarte", name: "Speisekarte" },
  { to: "/Information", name: "Information" },
  { to: "/", name: "Feedback" },
];

const NavBar = ({ features, setPagename }) => {
  const menu = features.find((f) => f.name === "menu");
  if (menu && menu.active) {
    dropdownItems = [
      { to: "/Speisekarte", name: "Speisekarte" },
      { to: "/Information", name: "Information" },
      { to: "/", name: "Feedback" },
    ];
  }
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const [className, setClassName] = useState();
  const location = useLocation();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  useEffect(() => {
    setPagename(`${location.pathname.substring(1)}`);
    // removeExtraItem();
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.indexOf("Feedback") !== -1) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [location.pathname]);

  return (
    <div className="nav-container">
      <Navbar light expand="md">
        <div className="choose-button">
          <span className="bold">
            {location.pathname.slice(1, 2).toUpperCase() +
              location.pathname.substring(2) || "Feedback"}
          </span>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav id="profileDropDown">
              <img
                src="./img/open-arrow.svg"
                className="open-arrow-img"
                alt="lalka"
              />
            </DropdownToggle>
            <DropdownMenu>
              {dropdownItems
                .filter(
                  (item) =>
                    item.to.toLowerCase() !== location.pathname.toLowerCase()
                )
                .map((item) => {
                  return (
                    <DropdownItem
                      tag={RouterNavLink}
                      to={item.to}
                      exact
                      activeClassName="router-link-exact-active"
                      id={`small-link${item.name}`}
                      onClick={close}
                      key={item.name.toString()}
                    >
                      {item.name === "QR-Code" && (
                        <img
                          src="/img/print-button-svgrepo-com.svg"
                          className="icon"
                          alt="print"
                        />
                      )}
                      {item.name}
                    </DropdownItem>
                  );
                })}
              <Link to={"/QrCode"}>
                <DropdownItem
                  className="bold small-link"
                  id="small-linkQR-Code"
                >
                  <img
                    src="/img/print-button-svgrepo-com.svg"
                    className="icon"
                    alt="print"
                  />
                  Qr Code runterladen
                </DropdownItem>
              </Link>
              <DropdownItem
                className="bold small-link"
                id="qsLogoutBtn"
                onClick={() => logoutWithRedirect()}
              >
                <img src="/img/logout.svg" className="icon" alt="logout" />
                Abmelden ({user.email})
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
