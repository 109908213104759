import "./App.css";
import { React, useEffect, useRef, useState } from "react";
import { Router, Switch, Route, Link } from "react-router-dom";

import OrderList from "./components/orders/OrderList";
import CustomerScreen from "./components/orders/CustomerScreen";
import MenuPage from "./pages/Menu";
import InformationPage from "./pages/Information";
import FeedbackPage from "./pages/Feedback";
import QrCodePage from "./pages/QrCode";
import Setup from "./components/Setup/Setup";
import NavBar from "./components/navbar/NavBar";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./util/history";
import ProtectedRoute from "./util/protected-route";
import axios from "axios";
import Feature from "./util/feature";
import { getBackground } from "./util/imageKit";
import Helmet from "react-helmet";

function App() {
  const endpoint = process.env.REACT_APP_API_ORIGIN;
  const {
    getAccessTokenSilently,
    user,
    isLoading,
    error,
    loginWithRedirect,
    isAuthenticated,
  } = useAuth0();

  const [businessId, setBusinessId] = useState();
  const [businessType, setBusinessType] = useState("default");
  const [features, setFeatures] = useState([]);
  const [pageName, setPageName] = useState();
  const [isUserInDB, setIsUserInDB] = useState(true);
  const [background, setBackground] = useState();
  const navbar = useRef(null);

  useEffect(() => {
    getisUserInDB();
  }, [user]);

  useEffect(() => {
    getBusinessType();
    fetchFeatures();
  }, [businessId]);

  useEffect(() => {
    setBackground(getBackground(businessType));
  }, [businessType]);

  useEffect(() => {}, [features]);

  if (isLoading) return <div>Loading...</div>;
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const handleSubmitSetup = (fields) => {
    createNewBusinesAndUser(fields);
  };

  async function createNewBusinesAndUser(fields) {
    if (fields) {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const linksEmail = { name: "email", url: fields.email };
        const links = [];
        links.push(linksEmail);
        const data = {
          business: {
            businessName: fields.businessName,
            businessType: fields.businessType,
            address: fields.address,
            information: {
              links: links,
            },
          },
          user: {
            username: user.email,
          },
        };
        const newBusiness = await axios.post(
          endpoint + "/business/new",
          { data: data },
          config
        );
        if (newBusiness) {
          getisUserInDB();
          history.push("/QrCode");
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function getisUserInDB() {
    if (user) {
      // console.log(user);
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const userFoundInDB = await axios.get(
          endpoint + "/user/" + user.email,
          config
        );
        if (userFoundInDB.data.data) {
          setBusinessId(userFoundInDB.data.data.localId);
          setIsUserInDB(true);
        } else {
          setIsUserInDB(false);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function getBusinessType() {
    if (businessId) {
      try {
        const res = await axios.get(
          endpoint + "/business/getBusinessType/" + businessId
        );
        setBusinessType(res.data.data);
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function fetchFeatures() {
    if (businessId) {
      try {
        const response = await axios.get(
          endpoint + "/business/features/" + businessId
        );
        setFeatures(response.data.data);
      } catch (err) {
        console.error(err);
      }
    }
  }

  return (
    <Router history={history}>
      <div>
        <Helmet bodyAttributes={{ style: background }} />
        <Setup
          user={user}
          visible={!isUserInDB}
          handleSubmitSetup={handleSubmitSetup}
        />

        {isAuthenticated ? (
          <div className="flex-grow-1">
            <div id="app" className="d-flex flex-column h-100">
              <div className="navref" ref={navbar}>
                <NavBar setPagename={setPageName} features={features} />
              </div>
              {/*add "container" class to flex-grow-1 below if you lose some styles*/}

              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  component={() => (
                    <FeedbackPage businessId={businessId} navbar={navbar} />
                  )}
                />
                <ProtectedRoute
                  exact
                  path="/information"
                  component={() => <InformationPage businessId={businessId} />}
                />

                <ProtectedRoute
                  exact
                  path="/QrCode"
                  component={() => <QrCodePage businessId={businessId} />}
                />

                <Feature name="menu" features={features}>
                  <ProtectedRoute
                    exact
                    path="/speisekarte"
                    component={() => <MenuPage businessId={businessId} />}
                  />
                </Feature>
                <Feature name="order" features={features}>
                  <ProtectedRoute
                    exact
                    path="/orders"
                    component={() => <OrderList businessId={businessId} />}
                  />
                </Feature>
                <Feature name="order" features={features}>
                  <ProtectedRoute
                    exact
                    path="/customerScreen"
                    component={() => <CustomerScreen businessId={businessId} />}
                  />
                </Feature>
              </Switch>
            </div>
          </div>
        ) : (
          loginWithRedirect()
        )}
      </div>
    </Router>
  );
}

export default App;
