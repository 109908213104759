import { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import { Formik } from "formik";
import { InputGroup, Form, FormControl } from "react-bootstrap";

const defaultInitialValues = {
  name: "",
  description: "",
  available: true,
  priceInEuros: 0,
  category: "",
};

const AddItemForm = ({
  visible,
  item,
  categories,
  handleSubmit,
  handleClose,
  handleDeleteItem,
}) => {
  const [dropdown, showDropdown] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const categoriesRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        categoriesRef.current &&
        !categoriesRef.current.contains(event.target)
      ) {
        showDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categoriesRef]);

  const close = () => {
    showDropdown(false);
    setNewCategory(false);
    handleClose();
  };
  return (
    <CSSTransition in={visible} timeout={300} classNames="dialog">
      <Modal
        ariaHideApp={false}
        closeTimeoutMS={500}
        isOpen={visible}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10,
          },
          content: {
            inset: "60px",
            border: "none",
            overflow: "inherit",
            padding: "0",
            justifyContent: "center",
          },
        }}
      >
        <Formik
          initialValues={{ ...defaultInitialValues, ...item }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Name ist erforderlich";
            }
            if (!values.priceInEuros || values.priceInEuros < 0) {
              errors.priceInEuros = "Preis ist erforderlich";
            }
            if (!values.category) {
              errors.category = "Erforderlich";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, item?.name ? true : false);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="addproduct-window-form">
              <div className="information-window">
                <div className="top-block margin0">
                  <div className="top-block__top">
                    <div className="top-block__top__left add-item">
                      <label htmlFor="name">Name</label>
                      <input
                        type="hidden"
                        name="category"
                        value={values.category}
                      />
                      <InputGroup className="name-input">
                        <FormControl
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          required
                          className={
                            errors.name
                              ? "error-border add-input common-input"
                              : "add-input common-input"
                          }
                          placeholder="Name des Artikels"
                          onChange={(e) => handleChange(e)}
                        />
                      </InputGroup>
                      <div className="error-block">
                        <span className="error-message">{errors.name}</span>
                      </div>
                    </div>
                    <div className="top-block__top__right add-item">
                      <label htmlFor="priceInEuros" className="price-label">
                        Preis in EURO
                      </label>
                      <InputGroup>
                        <FormControl
                          precision="2"
                          step="0.1"
                          as="input"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.priceInEuros}
                          name="priceInEuros"
                          className="FormControl"
                          placeholder=""
                          onChange={(e) => handleChange(e)}
                          required
                          className={
                            errors.priceInEuros
                              ? "error-border add-input common-input"
                              : "add-input common-input"
                          }
                        />
                      </InputGroup>
                      <div className="error-block">
                        <span className="error-message">
                          {errors.priceInEuros}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="top-block__bottom">
                    <label htmlFor="description">Beschreibung (Optional)</label>
                    <textarea
                      width="100%"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description
                          ? "error-border top-block__bottom__mid common-input"
                          : "top-block__bottom__mid common-input"
                      }
                      value={values.description}
                      placeholder="Beschreibung des Artikels"
                    />
                  </div>
                </div>
                <div className="bottom-block">
                  <div className="bottom-button-conatiner">
                    {item?.name ? (
                      <div
                        className="verfugbarkeit-block__bottom__right pointer"
                        onClick={() => {
                          if (handleDeleteItem(item) !== false) {
                            close();
                          }
                        }}
                      ></div>
                    ) : null}
                    <button
                      className="left-button pointer"
                      disabled={isSubmitting}
                      type="button"
                      onClick={close}
                    >
                      <span className="bold">Abbrechen</span>
                    </button>
                    <button
                      className="right-button pointer"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <span className="bold">Speichern</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </CSSTransition>
  );
};

export default AddItemForm;
