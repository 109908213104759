import React, { useEffect, useState } from "react";
import { weekDays } from "./constant";
import "./Information.css";

const WeekDay = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  day,
}) => {
  const [isOpen, setOpen] = useState(values.openingTimes[day].isOpen || false);
  return (
    <div className="left-block-item">
      <p className="left-block-item-child">{weekDays[day]}</p>
      <div className="switch-container">
        <label className="switch">
          <input
            type="checkbox"
            name={`openingTimes[${day}].isOpen`}
            defaultChecked={values.openingTimes[day].isOpen || false}
            onChange={handleChange}
            onBlur={handleBlur}
            onChange={handleChange}
            onChange={(e) => {
              setFieldValue(`openingTimes[${day}].isOpen`, !isOpen);
              setOpen(!isOpen);
            }}
          />
          <span className="slider round"></span>
        </label>
      </div>
      <p className="left-block-item-child second">
        {isOpen ? "Geöffnet" : "Geschlossen"}
      </p>
      <div className="left-block__input-container">
        <input
          name={`openingTimes[${day}].from`}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          className="common-input"
          placeholder="von ..."
          value={values.openingTimes[day].from}
          disabled={!isOpen}
        />
      </div>
      <div className="left-block__input-container">
        <input
          name={`openingTimes[${day}].to`}
          onChange={handleChange}
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          className="common-input"
          placeholder="bis ..."
          value={values.openingTimes[day].to}
          disabled={!isOpen}
        />
      </div>
    </div>
  );
};

const OpenTab = ({ isSelected, ...other }) => {
  if (!isSelected) return null;
  return (
    <div className="left-right-block">
      <div className="left-block offnungzeiten-left-block">
        {Object.keys(weekDays).map((key) => (
          <WeekDay day={key} {...other} key={key} />
        ))}
      </div>
    </div>
  );
};

export default OpenTab;
